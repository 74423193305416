<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Turnos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Consultas</li>
                  <li class="breadcrumb-item active">Turnos</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-6 pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Turnos</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-1">
                        <label for="" class="ml-1">Adicional</label>
                        <div class="form-group ml-1">
                          <div
                            class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success"
                          >
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="adicional"
                              v-model="form.adicional"
                              @change="limpiarCheck()"
                            />
                            <label
                              class="custom-control-label"
                              for="adicional"
                            ></label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-2">
                        <label>Fecha inicial</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form.fecha_inicial"
                          :class="
                            $v.form.fecha_inicial.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @input="validaFechas()"
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="hora_inicial">Hora inicial</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.hora_inicial"
                          :class="
                            $v.form.hora_inicial.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          v-mask="{
                            mask: '99:99:99',
                            hourFormat: '23',
                          }"
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label>Fecha final</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form.fecha_final"
                          :class="
                            $v.form.fecha_final.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @input="validaFechas()"
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="hora_final">Hora final</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.hora_final"
                          id="hora_final"
                          :class="
                            $v.form.hora_final.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          v-mask="{
                            mask: '99:99:99',
                            hourFormat: '23',
                          }"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="tipo_vehiculo">Tipo Vehículo</label>
                        <v-select
                          :class="[
                            $v.form.tipo_vehiculo_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="tipo_vehiculo"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.tipoVehiculo"
                          @input="getSelectTipoVehiculo()"
                          :disabled="!form.fecha_final"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-2">
                        <label for="vehiculo">Vehiculo</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          label="placa"
                          v-model="vehiculo.placa"
                          @change="buscarVehiculos()"
                          :class="
                            $v.form.vehiculo_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          :disabled="
                            !(
                              form.fecha_inicial != null &&
                              form.hora_inicial != null
                            )
                          "
                        />
                      </div>
                      <div
                        class="form-group col-md-2"
                        v-if="form.tipo_vehiculo_id == 1"
                      >
                        <label for="remolque">Remolque</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          label="placa"
                          v-model="remolque.placa"
                          @change="buscarRemolques()"
                          :class="
                            $v.form.remolque_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          :disabled="
                            !(
                              form.fecha_inicial != null &&
                              form.hora_inicial != null
                            )
                          "
                        />
                      </div>

                      <div class="form-group col-md-2">
                        <label for="conductor">Conductor</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          label="numero_documento"
                          v-model="conductor.numero_documento"
                          @change="buscarConductores()"
                          :class="
                            $v.form.conductor_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          :disabled="
                            !(
                              form.fecha_inicial != null &&
                              form.hora_inicial != null
                            )
                          "
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="conductor2">Conductor 2</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          label="numero_documento"
                          v-model="conductor2.numero_documento"
                          @change="buscarConductores2()"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="empresa">Empresa</label>
                        <v-select
                          :class="[
                            $v.form.empresa_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="empresa"
                          placeholder="Nombre"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.empresas"
                          @search="buscarEmpresas"
                          @input="selectEmpresas()"
                          :disabled="!this.form.tipo_vehiculo_id"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-2">
                        <label for="tipo_tarifa">Tipo Tarifa</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="form.tipo_tarifa"
                          :class="
                            $v.form.tipo_tarifa.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          :disabled="!this.form.empresa_id"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tarifa in listasForms.tipo_tarifas"
                            :key="tarifa.numeracion"
                            :value="tarifa.numeracion"
                          >
                            {{ tarifa.descripcion }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-2">
                        <label>Turno</label>
                        <v-select
                          :class="[
                            $v.form.turno_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="turno"
                          placeholder="Turno"
                          label="nombre"
                          :options="listasForms.turnos"
                          @input="getSelectTurno()"
                          class="form-control form-control-sm p-0"
                          :disabled="!form.tipo_tarifa"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-2">
                        <label for="horas_turno">Horas Turno</label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          id="horas_turno"
                          v-model="form.horas_turno"
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="ruta">Ruta</label>
                        <v-select
                          :class="[
                            $v.form.tif_ruta_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="ruta"
                          placeholder="Nombre"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.rutas"
                          :filterable="false"
                          @search="buscarRuta"
                          @input="selectRuta()"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-4">
                        <label>Bloque</label>
                        <v-select
                          :class="[
                            $v.form.bloque_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="bloque"
                          placeholder="Bloques"
                          label="nombre"
                          :options="listasForms.bloques"
                          @input="getSelectBloques()"
                          class="form-control form-control-sm p-0"
                          :disabled="!this.form.turno_id"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="operacion">Operación</label>
                        <v-select
                          :class="[
                            $v.form.tif_operacion_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="operacion"
                          placeholder="Operacion"
                          label="Noperacion"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.operaciones"
                          @input="getSelectOperacion()"
                          :disabled="!form.bloque_id"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="producto">Producto</label>
                        <v-select
                          :class="[
                            $v.form.producto_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="producto"
                          placeholder="Producto"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.tipoProducto"
                          :filterable="false"
                          @input="selectProducto()"
                          :disabled="!form.tif_operacion_id"
                        ></v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="$store.getters.can('tif.turnos.create')"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import { required, helpers } from "vuelidate/lib/validators";
import moment from "moment";
import Loading from "../../../../components/Loading";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "TifTurnoForm",
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      falgValidando: false,
      cargando: false,
      form: {
        tipo_vehiculo_id: null,
        vehiculo_id: null,
        conductor_id: null,
        conductor_2_id: null,
        remolque_id: null,
        empresa_id: null,
        fecha_inicial: null,
        hora_inicial: null,
        fecha_final: null,
        hora_final: null,
        fecha_ini: null,
        fecha_fin: null,
        adicional: false,
        tif_ruta_id: null,
        tipo_tarifa: null,
        horas_turno: null,
        producto_id: null,
        tif_operacion_id: null,
        turno_id: null,
        bloque_id: null,
        tipo_inicio: 2,
        estado: 1,
      },
      //arreglos
      accion: "",
      acto: null,
      metodo: "",
      vehiculo: [],
      conductor: [],
      conductor2: [],
      remolque: [],
      empresa: [],
      producto: [],
      ruta: [],
      operacion: {},
      bloque: {},
      turno: {},
      tipo_vehiculo: {},
      moment: moment,
      //listas
      listasForms: {
        vehiculos: [],
        conductores: [],
        conductores2: [],
        remolques: [],
        empresas: [],
        rutas: [],
        tipo_tarifas: [],
        operaciones: [],
        tipoVehiculo: [],
        tipoProducto: [],
        turnos: [],
        bloques: [],
      },
    };
  },
  validations() {
    if (this.form.tipo_vehiculo_id == 1) {
      return {
        form: {
          vehiculo_id: {
            required,
          },
          conductor_id: {
            required,
          },
          remolque_id: {
            required,
          },
          empresa_id: {
            required,
          },
          horas_turno: {
            required,
          },
          tipo_tarifa: {
            required,
          },
          fecha_inicial: {
            required,
          },
          hora_inicial: {
            required,
            timeFormat,
          },
          tif_operacion_id: {
            required,
          },
          tipo_vehiculo_id: {
            required,
          },
          producto_id: {
            required,
          },
          fecha_final: {
            required,
          },
          hora_final: {
            required,
            timeFormat,
          },
          turno_id: {
            required,
          },
          bloque_id: {
            required,
          },
          tif_ruta_id: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          vehiculo_id: {
            required,
          },
          conductor_id: {
            required,
          },
          empresa_id: {
            required,
          },
          horas_turno: {
            required,
          },
          tipo_tarifa: {
            required,
          },
          fecha_inicial: {
            required,
          },
          hora_inicial: {
            required,
            timeFormat,
          },
          tif_operacion_id: {
            required,
          },
          tipo_vehiculo_id: {
            required,
          },
          producto_id: {
            required,
          },
          fecha_final: {
            required,
          },
          hora_final: {
            timeFormat,
          },
          turno_id: {
            required,
          },
          bloque_id: {
            required,
          },
          tif_ruta_id: {
            required,
          },
        },
      };
    }
  },

  methods: {
    init() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      this.form.id = this.$route.params.id;
      if (this.$route.params.accion == "Crear") {
        this.metodo = "POST";
        this.form.id = null;
        this.acto = this.$route.params.acto;
      }
    },

    getOperacion() {
      axios
        .get("/api/tif/operaciones/lista", {
          params: {
            bloque_id: this.form.bloque_id,
          },
        })
        .then((response) => {
          this.listasForms.operaciones = response.data;
        });
    },

    getTipoTarifa() {
      axios.get("/api/lista/96").then((response) => {
        this.listasForms.tipo_tarifas = response.data;
      });
    },

    getTiposVehiculos() {
      const filtros = {
        linea_negocio_id: 3,
      };
      axios
        .get("/api/admin/tiposVehiculos/lista", {
          params: filtros,
        })
        .then((response) => {
          this.listasForms.tipoVehiculo = response.data;
        });
    },

    getTiposProducto() {
      axios
        .get("/api/tif/operaciones/opelistaProd", {
          params: {
            operacion_id: this.form.tif_operacion_id,
          },
        })
        .then((response) => {
          this.cargando = false;
          this.listasForms.tipoProducto = response.data;
        });
    },

    selectProducto() {
      this.form.producto_id = "";
      if (this.producto) {
        this.form.producto_id = this.producto.id;
        this.valTarifas();
      }
    },

    getBloques() {
      axios
        .get("/api/admin/bloques/lista", {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then((response) => {
          this.listasForms.bloques = response.data;
        });
    },

    getSelectBloques() {
      this.form.bloque_id = null;
      if (this.bloque) {
        this.form.bloque_id = this.bloque.id;
        this.getOperacion();
        this.operacion = "";
        this.form.tif_operacion_id = null;
      }
    },

    getSelectTipoVehiculo() {
      this.form.tipo_vehiculo_id = null;
      if (this.tipo_vehiculo) {
        this.form.tipo_vehiculo_id = this.tipo_vehiculo.id;
      }
    },

    getSelectOperacion() {
      this.form.tif_operacion_id = null;
      if (this.operacion) {
        this.form.tif_operacion_id = this.operacion.id;
        this.getTiposProducto();
      }
    },

    getTurnos() {
      const filtros = {
        linea_negocio_id: 3,
        estado: 1,
      };
      axios
        .get("/api/admin/turnos/lista", {
          params: filtros,
        })
        .then((response) => {
          this.listasForms.turnos = response.data;
        });
    },

    getSelectTurno() {
      this.form.turno_id = null;
      let day = moment(this.form.fecha_inicial).day();
      if (day == 0) {
        day = 7;
      }

      let fecha_ini = moment(
        this.form.fecha_inicial + " " + this.form.hora_inicial
      );
      let fecha_fin = moment(
        this.form.fecha_final + " " + this.form.hora_final
      );

      if (this.form.adicional == false) {
        if (this.turno) {
          this.form.turno_id = this.turno.id;
          for (let i = 0; i < this.turno.dia_turno.length; i++) {            
            if (this.turno.dia_turno[i].dia_semana == day) {
              this.form.horas_turno = this.turno.dia_turno[i].cant_horas;
            }
          }
        } else {
          this.form.horas_turno = "";
        }
      } else {
        if (this.turno) {
          this.form.turno_id = this.turno.id;
          let diff = fecha_fin.diff(fecha_ini, "m");
          let horas = diff / 60; // Diferencias en horas

          this.form.horas_turno = horas;
        }
      }
    },

    async validarStatus(dato, tipo) {
      let me = this;
      let url = "";
      let entidad = null;
      if (tipo == "C") {
        entidad = "Conductor";
        url = "api/tif/turnos/buscarConductor";
      } else if (tipo == "R") {
        entidad = "Remolque";
        url = "api/tif/turnos/buscarRemolque";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "api/tif/turnos/buscarCabezote";
      }

      let params = {
        entidad,
        dato,
        fecha_ini: this.form.fecha_inicial,
        hora_ini: this.form.hora_inicial,
        fecha_fin: this.form.fecha_final,
        hora_fin: this.form.hora_final,
      };

      var res = null;
      await axios
        .get(url, {
          params,
        })
        .then((response) => {
          res = response.data;
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },

    async buscarVehiculos() {
      this.cargando = true;
      this.falgValidando = false;
      let dato = this.vehiculo.placa;
      let res = await this.validarStatus(dato, "V");
      if (res && res.length != 0) {
        //Validación 2 horas de reporte gps
        if (res[0].ultimo_registro) {
          //Se obtiene fecha actual
          const fechaAct = moment().format("YYYY-MM-DD HH:mm:ss");
          //Se le resta 2 horas a la fecha actual
          const fechaCorte = moment(fechaAct)
            .add(-2, "hours")
            .format("YYYY-MM-DD HH:mm:ss");
          //Se obtiene la fecha de reporte del vehículo
          const fechaGps = moment(
            res[0].ultimo_registro.fecha_hora_ultimo_reporte
          ).format("YYYY-MM-DD HH:mm:ss");
          if (fechaCorte > fechaGps) {
            this.cargando = false;
            await this.$swal({
              text: "El vehículo no ha reportado en las 2 últimas horas",
              icon: "warning",
              confirmButtonText: "Aceptar!",
            });
            this.selectVehiculo(res[0].id);
          }
          this.cargando = false;
        } else {
          this.cargando = false;
          await this.$swal({
            title: "No es posible digitar este vehículo!!",
            text: "El vehículo no tiene tramas satelitales a la fecha",
            icon: "error",
            confirmButtonText: "Aceptar!",
          });
          this.vehiculo = {};
          this.form.vehiculo = {};
          this.form.vehiculo_id = null;
          this.cargando = false;
          return false;
        }

        //Validar si tiene inspeccion activa
        if (res.inspeccion != null) {
          if (res.inspeccion.estado == 4) {
            this.vehiculo = {};
            this.form.vehiculo = {};
            this.form.vehiculo_id = null;
            await this.$swal({
              icon: "error",
              title: `La inspeccion del vehículo se encuentra en estado rechazado`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          } else if (res.inspeccion.estado == 5) {
            await this.$swal({
              icon: "success",
              title: `La inspeccion del vehículo se encuentra aprobada con novedad`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return true;
          } else if (res.inspeccion.estado == 2) {
            await this.$swal({
              icon: "success",
              title: `La inspeccion del vehículo se encuentra aprobada`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          } else if (res.inspeccion.estado == 3) {
            await this.$swal({
              icon: "success",
              title: `La inspeccion del vehículo se encuentra autorizada`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          }
        } else {
          this.vehiculo = {};
          this.form.vehiculo = {};
          this.form.vehiculo_id = null;
          await this.$swal({
            icon: "error",
            title: `El vehículo no cuenta con inspección vigente`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }

        //Validación se encuentra activo dentro de un Turno
        if (res.turno.length > 0) {
          this.vehiculo = {};
          this.form.vehiculo = {};
          this.form.vehiculo_id = null;
          await this.$swal({
            icon: "error",
            title: `El vehículo se encuentra activo dentro de un turno`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }

        //Validar si ya se encuentra con una postulacion
        if (res.postulacion.length > 0) {
          let fecha = moment(res.postulacion[0].fecha_ini).format("YYYY-MM-DD");
          this.vehiculo = {};
          this.form.vehiculo = {};
          this.form.vehiculo_id = null;
          await this.$swal({
            icon: "error",
            title: `El vehículo ya está postulado para la fecha ${fecha}`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }

        //Valida si se encuentra en la linea perteneciente
        if (!res["has_lineas"]) {
          await this.$swal({
            icon: "error",
            title: `El vehiculo no se encuentra en la linea de negocio Transportes Internos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.vehiculo = {};
          this.form.vehiculo = {};
          this.form.vehiculo_id = null;
          this.cargando = false;
          return false;
        }

        //Validar si el vehículo tiene peso asignado.
        if (res[0].peso_vacio == null || res[0].peso_vacio == 0) {
          this.vehiculo = {};
          this.form.vehiculo = {};
          this.form.vehiculo_id = null;
          await this.$swal({
            icon: "error",
            title: `El vehículo no tiene peso asignado.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.cargando = false;
          this.falgValidando = false;
          return false;
        }
        await this.$swal({
          icon: "success",
          title: "El vehículo es apto para creación turno manual",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
        this.selectVehiculo(res[0].id);
      } else {
        await this.$swal({
          icon: "error",
          title:
            "El vehículo digitado NO se encuentra registrado, pruebe con otra placa.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
        this.vehiculo = {};
        this.form.vehiculo = {};
        this.form.vehiculo_id = null;
      }
      this.cargando = false;
    },

    selectVehiculo(vehId) {
      this.form.vehiculo_id = "";
      if (this.vehiculo) {
        this.form.vehiculo_id = vehId;
      }
    },

    async buscarRemolques() {
      this.cargando = true;
      this.falgValidando = true;
      let dato = this.remolque.placa;
      let res = await this.validarStatus(dato, "R");
      if (res && res.length != 0) {
        if (res.turno.length > 0) {
          this.remolque = {};
          this.form.remolque = {};
          this.form.remolque_id = null;
          await this.$swal({
            icon: "error",
            title: `El remolque se encuentra activo dentro de un turno`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }
        //Valida la información completa
        if (res["info"] == false) {
          await this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Relmoques`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.remolque = {};
          this.form.remolque = {};
          this.form.remolque_id = null;
          this.cargando = false;
          return false;
        }
        //Valida si se encuentra en la linea perteneciente
        if (!res["has_lineas"]) {
          await this.$swal({
            icon: "error",
            title: `El Remolque no se encuentra en la linea de negocio Transporte Interno`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.remolque = {};
          this.form.remolque = {};
          this.form.remolque_id = null;
          this.cargando = false;
          return false;
        }
        //Valida si se encuentra con un turno activo
        if (res.turno.length > 0) {
          this.remolque = {};
          this.form.remolque = {};
          this.form.remolque_id = null;
          await this.$swal({
            icon: "error",
            title: `El remolque se encuentra activo dentro de un turno`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }
        if (res.postulacion.length > 0) {
          let fecha = moment(res.postulacion[0].fecha_ini).format("YYYY-MM-DD");
          this.remolque = {};
          this.form.remolque = {};
          this.form.remolque_id = null;
          await this.$swal({
            icon: "error",
            title: `El remolque ya está postulado para la fecha ${fecha}`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
          //Validar si el rermolque tiene peso asignado.
        }
        if (res[0].peso_vacio == null || res[0].peso_vacio == 0) {
          this.remolque = {};
          this.form.remolque = {};
          this.form.remolque_id = null;
          await this.$swal({
            icon: "error",
            title: `El remolque no tiene peso asignado.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          this.cargando = true;
          return false;
        }
        await this.$swal({
          icon: "success",
          title: "El remolque es apto para creación turno manual",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
        this.selectRemolque(res[0].id);
      } else {
        await this.$swal({
          icon: "error",
          title:
            "El remolque digitado NO se encuentra registrado, pruebe con otra placa.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
        this.remolque = {};
        this.form.remolque = {};
        this.form.remolque_id = null;
      }
      this.cargando = false;
    },

    selectRemolque(RemId) {
      this.form.remolque_id = "";
      if (this.remolque) {
        this.form.remolque_id = RemId;
      }
    },

    async buscarConductores() {
      this.cargando = true;
      this.falgValidando = true;
      let dato = this.conductor.numero_documento;
      this.form.conductor = {};
      let res = await this.validarStatus(dato, "C");
      if (res && res.length != 0) {
        if (res["info"] == false) {
          await this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Conductores`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.conductor = {};
          this.form.conductor = {};
          this.cargando = false;
          return false;
        }
        //Valida si se encuentra en la línea perteneciente
        if (!res["has_lineas"]) {
          await this.$swal({
            icon: "error",
            title: `El Conductor no se encuentra en la linea de negocio Transporte Interno`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.conductor = {};
          this.form.conductor = {};
          this.form.conductor_id = null;
          this.cargando = false;
          return false;
        }
        //Valida si se encuentra con un turno activo
        if (res.turno.length > 0) {
          this.conductor = {};
          this.form.conductor = {};
          this.form.conductor_id = null;
          await this.$swal({
            icon: "error",
            title: `El conductor se encuentra activo dentro de un turno`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }
        //Valida si se encuentra con una postulación activa
        if (res.postulacion.length > 0) {
          let fecha = moment(res.postulacion[0].fecha_ini).format("YYYY-MM-DD");
          this.conductor = {};
          this.form.conductor = {};
          this.form.conductor_id = null;
          await this.$swal({
            icon: "error",
            title: `El conductor ya está postulado para la fecha ${fecha}`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.cargando = false;
          this.falgValidando = false;
          return false;
        }
        await this.$swal({
          icon: "success",
          title: "El conductor es apto para creación turno manual",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
        this.selectConductor(res[0].id);
      } else {
        this.conductor = {};
        this.form.conductor = {};
        this.form.conductor_id = null;
        await this.$swal({
          icon: "error",
          title:
            "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
      this.cargando = false;
    },

    selectConductor(CondId) {
      this.form.conductor_id = "";
      if (this.conductor) {
        this.form.conductor_id = CondId;
      }
    },

    async buscarConductores2() {
      this.cargando = true;
      this.falgValidando = true;
      this.form.conductor2 = {};
      let dato = this.conductor2.numero_documento;

      let res = await this.validarStatus(dato, "C");
      if (res && res.length != 0) {
        /*  if (res.validacion.error) {
          await this.msgDocumentacion(res);
        } */
        //Valida si se encuentra con la infomación completa
        if (res["info"] == false) {
          await this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Conductores`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.conductor2 = {};
          this.form.conductor2 = {};
          this.form.conductor_2_id = null;
          this.cargando = false;
          return false;
        }
        //Valida si se encuentra en la línea perteneciente
        if (!res["has_lineas"]) {
          await this.$swal({
            icon: "error",
            title: `El Conductor no se encuentra en la linea de negocio Transporte Interno`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.form.conductor2 = {};
          this.form.conductor_2_id = null;
          this.cargando = false;
          return false;
        }
        //Valida si se encuentra con un turno activo
        if (res.turno.length > 0) {
          this.conductor2 = {};
          this.form.conductor2 = {};
          this.form.conductor_2_id = null;
          await this.$swal({
            icon: "error",
            title: `El conductor 2 se encuentra activo dentro de un turno`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }
        //Valida si se encuentra con una postulación activa
        if (res.postulacion.length > 0) {
          let fecha = moment(res.postulacion[0].fecha_ini).format("YYYY-MM-DD");
          this.conductor2 = {};
          this.form.conductor2 = {};
          this.form.conductor_2_id = null;
          await this.$swal({
            icon: "error",
            title: `El conductor 2 ya está postulado para la fecha ${fecha}`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.cargando = false;
          this.falgValidando = false;
          return false;
        }
        await this.$swal({
          icon: "success",
          title: "El conductor 2 es apto para creación turno manual",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
        this.selectConductor2(res[0].id);
      } else {
        this.conductor2 = {};
        this.form.conductor2 = {};
        this.form.conductor_2_id = null;
        await this.$swal({
          icon: "error",
          title:
            "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
      this.cargando = false;
    },

    selectConductor2(Cond2Id) {
      this.form.conductor_2_id = "";
      if (this.conductor2) {
        this.form.conductor_2_id = Cond2Id;
      }
    },

    buscarEmpresas() {
      let me = this;
      me.listasForms.empresas = [];
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url, {
          params: {
            linea_negocio_id: [3],
          },
        })
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectEmpresas() {
      this.form.empresa_id = "";
      if (this.empresa) {
        this.form.empresa_id = this.empresa.id;
      }
    },

    buscarRuta(search) {
      let me = this;
      me.listasForms.rutas = [];
      if (search != "") {
        var url = "api/tif/rutas/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.rutas = response.data;
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectRuta() {
      this.form.tif_ruta_id = "";
      if (this.ruta) {
        this.form.tif_ruta_id = this.ruta.id;
      }
    },

    valTarifas() {
      this.form1 = {
        operacion_id: this.form.tif_operacion_id,
        tipo_tarifa: this.form.tipo_tarifa,
        tipo_vehiculo: this.form.tipo_vehiculo_id,
        tipo_turno: this.form.turno_id,
        tif_producto_id: this.form.producto_id,
        fecha_fin: this.form.fecha_final + " " + this.form.hora_final,
        bloque_id: this.form.bloque_id,
        empresa: this.form.empresa_id,
      };
      axios({
        method: "GET",
        url: "/api/tif/ventanasGeneralOperaciones/valTarifas",
        params: this.form1,
      }).then((response) => {
        this.validar = response.data.estado;
        if (this.validar == 2) {
          this.$swal({
            icon: "error",
            title: `No se puede realizar el cambio ya que no se encuentra una tarifa...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          this.form.tipo_tarifa = null;
          this.form.fecha_final = null;
          this.form.tif_operacion_id = null;
          this.form.tipo_vehiculo_id = null;
          this.form.turno_id = null;
          this.form.producto_id = null;
          this.form.empresa_id = null;
          this.form.bloque_id = null;
          this.operacion = {};
          this.tipo_vehiculo = {};
          this.turno = {};
          this.producto = {};
          this.empresa = {};
          this.bloque = {};
        }
      });
    },

    save() {
      let fechaAct = moment().format("YYYY-MM-DD HH:mm:ss");
      this.form.fecha_ini =
        this.form.fecha_inicial + " " + this.form.hora_inicial;
      this.form.fecha_fin = this.form.fecha_final + " " + this.form.hora_final;

      if (fechaAct > this.form.fecha_ini) {
        this.form.estado = 8;
      }
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/tif/turnos",
          data: this.form,
        })
          .then(() => {
            (this.id = this.form.id),
              this.$swal({
                icon: "success",
                title: "El  turno se guardo exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            this.cargando = false;
            this.back();
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    limpiarCheck() {
      this.form.turno_id = null;
      this.turno = null;
      this.form.horas_turno = null;
    },

    validaFechas() {
      let fecha_menor = this.form.fecha_inicial;
      let fecha_mayor = this.form.fecha_final;
      if (this.form.fecha_inicial || this.form.fecha_final) {
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    back() {
      return this.$router.replace("/tif/turnos");
    },
  },
  async mounted() {
    await this.init();
    await this.buscarEmpresas();
    await this.getOperacion();
    await this.getTiposVehiculos();
    await this.getTiposProducto();
    await this.getTipoTarifa();
    await this.getTurnos();
    await this.getBloques();
  },
};
</script>
